<template>
  <div class="blood-oxygen-report-configuration">
    <div class="blood-oxygen-report-configuration-container">
      <div class="blood-oxygen-report-configuration-container-top">
        <button v-show="currentComponent === 'display'" class="blood-oxygen-report-configuration-container-top-edit"
          @click="onEditClick">
          编辑
        </button>
      </div>
      <div class="blood-oxygen-report-configuration-container-bottom">

        <component :is="currentComponent" :model="settingInfoModel" @onCancelClick="onEditCancelClick"
          @onSaveClick="onSaveClick" :canSwitch="currentComponent === 'display'" :isCombineDateAndEvent="isCombineDateAndEvent" :isCombineSignAndPulse="isCombineSignAndPulse"></component>
        
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Display: () => import("@c/setting/blood-oxygen-report-configuration/display/display.vue"),
    Edit: () => import("@c/setting/blood-oxygen-report-configuration/edit/edit.vue")
  },

  data() {
    return {
      currentComponent: "display",
      rawSettingInfoModel: {},
      editedSettingInfoModel: {},
      isCombineSignAndPulse:false,
      isCombineDateAndEvent:false,
    };
  },

  computed: {
    hospitalID: function () {
      return this.$store.state.selectedHospital.hospitalid;
    },
    settingInfoModel() {
      const settingInfoModel = this.rawSettingInfoModel;
      return settingInfoModel;
    },
  },

  watch: {
    hospitalID: function () {
      this.currentComponent = "display";
      this.getInfo();
    },
  },

  created() {
    this.getInfo();
  },

  methods: {
    onEditClick() {
      if (this.currentComponent === "display") {
        this.currentComponent = "edit";
      } else {
        this.currentComponent = "display";
      }
    },

    onEditCancelClick() {
      this.currentComponent = "display";
    },

    onSaveClick(settingInfoModel) {
      this.editedSettingInfoModel = settingInfoModel;
      this.$emit("onSaveClick")
    },

    async getInfo() {
      try {
        const params = {
          "hospitalId": this.hospitalID
        };
        const data = await this.$api.getBloodOxygenReportConfig(params);
        this.rawSettingInfoModel = data;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async saveInfo() {
      try {
        let model = this.editedSettingInfoModel
        const params = {
          "hospitalId": this.hospitalID,
          sopReportHeaders: model.sopReportHeaders,
          sopReportSign: model.sopReportSign,
          sopReportSignDate: model.sopReportSignDate,
          sopReportSubTitle: model.sopReportSubTitle,
          sopReportLogo: model.sopReportLogo,
        };
        const _ = await this.$api.editBloodOxygenReportConfig(params);
        this.$toast.showGreen("报告配置成功");
        this.currentComponent = "display";
        this.getInfo();
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.blood-oxygen-report-configuration {
  background-color: white;
  border-radius: rem(2);
  width: 100%;
  height: 100%;

  &-container {
    height: 100%;
    overflow: hidden;
    // overflow-y: auto;
    box-sizing: border-box;
    padding: rem(0) rem(36) rem(50);

    &-top {
      width: 100%;
      height: rem(72);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-edit {
        color: #325FAC;
        font-size: rem(14);
        border-radius: rem(4);
        border: rem(1) solid #325FAC;
        width: rem(88);
        height: rem(32);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-bottom {
      width: 100%;
       
    }
  }
}
</style>